import { useParams } from 'react-router-dom';
import React, {useState,useEffect} from "react";
import useFetchAssistants from '../hooks/useFetchAssistants';
import useFetchDataSources from '../hooks/useFetchDataSources';
import useUpdateAssistants from '../hooks/useUpdateAssistants';
import useFetchAnswers from '../hooks/useFetchAnswers';
import { useNavigate } from 'react-router-dom';
import button_icon from "../assets/IconButton.svg";
import button_plus from "../assets/button_plus.svg";
import submit_icon from "../assets/submit.svg";
import ActionMenu from "./ActionMenu";
import { useAuth } from '../hooks/useAuth';
import edit_icon from "../assets/edit_grey.png";
import Modal from "./AddFileModal";
import ReactJson from 'react-json-view';
import MonacoEditor from '@monaco-editor/react';
//import ReactMarkdown from 'react-markdown';
import {marked} from 'marked';
import { format } from 'date-fns';

import loading from '../assets/loading.gif';

import DataTable from './DataTable';




function EditAssistants() {
  const { assist_ID } = useParams();
  const { user} = useAuth();
  const navigate = useNavigate();
  const [currentAssist,setCurrentAssistant] = useState([]);
  const [datasources, setDataSource] = useState([]);
  const [currentDS, setCurrentDS] = useState({});
  const [files,setFiles] = useState([]);
  const [showFile, setShowFile] = useState(false);
  const [isRename,setIsRename] = useState(false)
  const [newName,setNewName] = useState('')
  const [updateDetails, setUpdateDetails] = useState(null);
  const updateAssistants = useUpdateAssistants(user, navigate);



  const [connecting,setConnecting] = useState(false)
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [reasonMessage, setResonMessage] = useState(null);
  

  //train the assistants
  const fetchAnswers = useFetchAnswers(user, navigate);
  const [messagesContent,setMessages] = useState('');
  const [messageId,setMessageId] = useState('');
  const [SQLCode,setSQLCode] = useState('');
  const [newSQLCode,setNewSQLCode] = useState(null);
  const [rawData,setRawData] = useState([]);
  const [question,setQuestion] = useState('');

  const [isCorrect, setIsCorrect] = useState(null);
  const [fileloading,setFileLoading] = useState(null);



  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    window.location.reload();
  };

  const onClose = () => {
    setShowFile(false);
    //window.location.reload();
  }

  console.log("the current assistant is: ",assist_ID)
  useFetchAssistants(user, navigate, setCurrentAssistant,assist_ID);
 console.log(currentAssist)



 const source_id = currentAssist.map(item => item.source_id);
console.log("the data source is:",source_id[0])
useFetchDataSources(user, navigate, setDataSource,source_id[0]);



 const handleCorrectChange = (response) => {
    setIsCorrect(response);
};


  const [jsonData, setJsonData] = useState({});

const EditFile = (edit) => {
    setJsonData(edit.updated_src);
    console.log("the updated json is:",jsonData)
    console.log("the updated raw file is:",edit.updated_src)
    setUpdateDetails({
        type:"Update File",
        key:showFile,
        content:edit.updated_src
    })
};

const assistantRename = () =>{
    console.log("new name is:",newName)
    setUpdateDetails({
        name:newName
    })  
};

const handleTextareaChange = (event) => {
    setQuestion(event.target.value);
    //console.log("with content change:",question);
  };

  ///if new code is input
  const handleEditorChange = (value) => {
    setNewSQLCode(value);
    //console.log("the correct answer is:",newSQLCode)
  };

//save the query
const SaveQuery = () => {
    const now = new Date();
    const formattedDate = format(now, 'yyyy-MM-dd-HH-mm-ss');
    const key =  'SavedQueries/' + user.uid + '/' + formattedDate + '-Saved_Queries.json';
    const data = {
        update_time:now,
        added_by:user.uid,
        question:question,
        feedback:isCorrect,
        context:reasonMessage,
        correct_code: newSQLCode ? newSQLCode : SQLCode
    }
    console.log("saved query is: ",data)
    
    fetch('https://api.qquest.io/chat-feedback', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${user.token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            message_id: messageId,
            rating: isCorrect,
            rating_details:reasonMessage
        })
    })
    .then(response => response.json())
    .then(data => console.log('Export successful', data))
    .catch(error => console.log('Error exporting data:', error));

    setUpdateDetails({
        type:"Save Query",
        key:key,
        content:data
    })
};

const handleSaveQuery = (e) => {
    e.preventDefault(); // Prevent form submission
    SaveQuery(); // Call your SaveQuery function
};

const RunQuery = () => {
    console.log("the sql is:", newSQLCode);
    console.log("current data source:",currentDS);
    fetch('https://api.qquest.io/portal-test-query', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({ 
                    user_id: user.uid, 
                    source_id: currentDS.source_id,
                    query:newSQLCode
                 }),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log("Return value in context:", data.data);
                if (Array.isArray(data.data) && data.data.length > 0) {
                    console.log("there is the data:",data.data)
                    const headers = data.data.length > 0 ? Object.keys(data.data[0]) : [];
                    setRawData(data.data)
                } else {
                    console.log("No valid file keys found in the response.");
                   
                }
            })
            .catch(error => {
                console.log("Error running the query", error);
            });
};

///update assistants
useEffect(()=>{

    if (updateDetails && Object.keys(updateDetails).length > 0) {
        console.log("running assistant update:")
        console.log(updateDetails)
        try {
            updateAssistants(user, navigate, updateDetails, assist_ID);
            setIsRename(false)
            setUpdateDetails(null)
            setSuccessMessage("The assistant hase been updated successfully.")
        } catch(error){
            setErrorMessage("The assistant update is failed.")
        }
        
      }
},[updateDetails,user,navigate,assist_ID])

const getHtml = () => {
    return { __html: marked(messagesContent) };
  };

///train assistants by asking questions
const handleFormSubmit = (event) => {
    event.preventDefault();
    setConnecting(true);
    console.log('Connecting0:', connecting); // Log connecting state
    
    console.log('Connecting1:', connecting); // Log connecting state

    if (question && question.length > 0) {
        //console.log(question)
        console.log("current ds:")
        console.log(currentDS)
        

        try {
            fetchAnswers(user, navigate, currentDS.name, question, setSQLCode, setMessageId, setMessages, setRawData, assist_ID,currentDS.source_id)
                .then(() => {
                    setConnecting(false); // Update state after fetching answers
                })
                .catch((error) => {
                    setConnecting(false);
                    setErrorMessage("There is something wrong.");
                });
        } catch (error) {
            setConnecting(false);
            setErrorMessage("There is something wrong.");
        }
        
      }
};

 // Use useEffect to log state changes
 useEffect(() => {
    console.log('Connecting state changed:', connecting);
}, [connecting]);


  useEffect(() => {
    if (datasources.length > 0 && currentAssist.length > 0) {
        const filelist = [];
        if(currentAssist[0].Instruction_Files.length > 0) {
            filelist.push(currentAssist[0].Instruction_Files[0])
        }

       // console.log("filelist0:",filelist);

        //const data = JSON.parse(currentAssist[0].Context_Files[0]);
      //  console.log("context file string:",data)
         // Check if file_keys exists
         if (currentAssist[0].Context_Files !== '') {
            // Extract the .json file from file_keys
            console.log("The context file id is:",currentAssist[0].Context_Files)
            fetch('https://api.qquest.io/fetch-context-files', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({ UserId: user.uid, itemID: currentAssist[0].Context_Files }),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log("Return value in context:", data.data);
                if (Array.isArray(data.data) && data.data.length > 0) {
                    filelist.push(...data.data.map(item => item.file_key));
                    // console.log("filelist 1 is:",filelist)
                    setFiles(filelist)
                    console.log("files are:",files)
                    setFileLoading(true)
                } else {
                    console.log("No valid file keys found in the response.");
                    setFiles(filelist)
                    setFileLoading(true)
                }
            })
            .catch(error => {
                console.log("Error fetching assistants", error);
            });
        } else {
            console.log("there is no file in context files.")
            setFiles(filelist)
        }

        //setFiles(currentAssist[0].Instruction_Files);
   
            setFiles(filelist)
             console.log("files are:",files)
        
        
        const foundDS = datasources.find(ds => ds.source_id === currentAssist[0].source_id);
        if (foundDS) {
            setCurrentDS(foundDS);
        }
    }


}, [datasources, currentAssist]);
//console.log("currentAssist:",currentAssist)

console.log("raw data is:", rawData)



const totalElements = SQLCode.length;
const codeLength = totalElements > 0 ? `${(1 / totalElements) * 100}%` : '100%';


  return (
    <div>
        <div className="flex items-center space-x-2 p-4">
            <p className="text-blue-500 text-sm cursor-pointer hover:underline" onClick={() => navigate('/dashboard/assistants')}>
                All assistants
            </p>
            <p>&gt;</p>

            {Array.isArray(currentAssist) && currentAssist.map((item, index) => (
                <p className="font-semibold text-sm">{item.AssistantName}</p>
            ))}
            
        </div>
        {successMessage && <p className="bg-green-200 text-green-800 p-2 rounded-md mb-4">{successMessage} 
            <span
                className="ml-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                onClick={() => window.location.reload()}
                >
                Refresh
            </span>
        </p>}
        {errorMessage && <p className="bg-red-200 text-red-800 p-2 rounded-md mb-4">{errorMessage}</p>}
        <div className="flex items-center space-x-2 px-4 pt-4 pb-1 ">
        {Array.isArray(currentAssist) && currentAssist.map((item, index) => (
            <div className="flex items-center ">
                <p className='text-sm'>{item.AssistantName}</p>
                <div className="relative group">
                    <img src={edit_icon} alt="Edit Icon" className="w-auto h-auto object-cover" onClick={() => setIsRename(true)}/>
                    <div className="absolute bottom-full mb-2 hidden group-hover:block bg-black text-white text-xs rounded px-3 py-1 whitespace-nowrap">
                        Rename
                    </div>
                </div>
            </div>
            
        ))}
            
            {isRename && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-4 rounded shadow-lg">
                    <h2 className="text-lg font-semibold mb-2">Rename Assistant</h2>
                    <label>New Assistant Name:</label>
                    <input
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        className="border p-1 mb-4 w-full"
                    />
                    <div className="flex justify-end space-x-2">
                        <button onClick={() => setIsRename(false)} className="flex items-center bg-gray-200 border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-50">Cancel</button>
                        <button onClick={assistantRename} className="flex items-center bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-50">Save</button>
                    </div>
                </div>
                </div>
            )}
            
                    
        </div>

        <div className="overflow-x-auto mt-1 mb-1 ml-4 mr-4 rounded-md shadow ">
            <table className="min-w-full bg-white  ">
                <thead className="font-bold">
                    <tr>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Data Source Name
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Source Type
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Created At
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                        </th>
                        {/* Add other headers as needed */}
                    </tr>
                </thead>
              
                <tbody className="bg-white divide-y divide-gray-200">
                  
                  {currentDS ? (
                        <tr>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{currentDS.name}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{currentDS.DatabaseType}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{currentDS.created_at}</td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                                {currentDS.CountAssit > 0 ? 
                                  <p className="text-[#166534] bg-[#DCFCE7] rounded-lg px-4 py-1 w-30 text-center ">Active</p> 
                                  : <p className="text-[#1F2937] bg-[#E5E7EB] rounded-lg px-4 py-1 w-30 text-center ">Not in Use</p>}
                            </td>
                           
                            {/* Render other fields as needed */}
                        </tr>
                    ) : (
                        <div></div>
                    )}
                </tbody>
            </table>
        </div>

        <div className='flex items-center space-x-2 px-4 pt-4 pb-1 justify-between'>
            <div className='flex items-center'>
                <p className="font-semibold text-sm">Training materials</p>
                <div className="relative group" onClick={openModal}>
                    <img src={button_plus} alt="Add a File Icon" className="w-auto h-auto object-cover" />
                    <div className="absolute bottom-full mb-2 hidden group-hover:block bg-black text-white text-xs rounded px-3 py-1 whitespace-nowrap">
                        Add a new training file
                    </div>
                    <Modal isOpen={isModalOpen} onClose={closeModal} user={user} setUpdateDetails={setUpdateDetails}>
                    </Modal>
                </div>
            </div>
            
            <a href="https://drive.google.com/drive/folders/1arLbu1WBaBIAcYLXEU3bTY6d8Aw8lSq_?usp=sharing" className="flex-shrink-0 ml-6 mt-1 p-2 text-blue-500 hover:text-blue-700 text-sm">Download template</a>

        </div>
{/* Add more details about the training materials here */}
        <div className="overflow-x-auto mt-1 mb-1 ml-4 mr-4 rounded-md shadow ">
            <table className="min-w-full bg-white  ">
                <thead className="font-bold">
                    <tr>
                        <th scope="col" className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            File Name
                        </th>
                        <th scope="col" className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            File Category
                        </th>
                        <th scope="col" className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Description
                        </th>
                        <th scope="col" className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                           
                        </th>
                        {/* Add other headers as needed */}
                    </tr>
                </thead>
              
                <tbody className="bg-white">
                  
                {Array.isArray(files) && files.map((item, index) => (
                        <tr>
                            <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-900">{item.split('/')[2]}</td>
                            <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-900">{item.split('/')[0]}</td>
                            <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                                {item.split('/')[0] === "MetricFiles" ? 
                                  <p className="text-sm  ">Metrics definitions, calculations and code sample</p> 
                                  : item.split('/')[0] === "DictionaryFiles" ?
                                  <p className="text-sm ">Explain the meaning of data</p>
                                  : item.split('/')[0] === "Others" ?
                                  <p className="text-sm  ">More context</p>
                                  :
                                  <p className="text-sm  ">Saved samples from training, includes questions, answers or code samples</p>
                                }
                            </td>
                            <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
                              <ActionMenu 
                                buttonIcon={button_icon} 
                                itemId={item}
                                page ="Files"
                                user = {user}
                                setState={setShowFile}
                                setData={setJsonData}
                                setDelete={setUpdateDetails}/>
                            </td>
                           
                            {/* Render other fields as needed */}
                        </tr>
                    ) 
                    )}
                </tbody>
            </table>
        </div>
        {/* Render file view */}
        { showFile && (
            <div className='space-x-2 px-4 pt-4 pb-1 '>
                <div className="bg-white rounded-md shadow px-6 py-2  max-h-48 overflow-y-auto ">
                    <div className="flex items-center justify-between pb-2">
                   
                        <p className="font-semibold text-sm ">{showFile.split('/')[2]}</p>
                        <button onClick={onClose} className="ml-4 flex-shrink-0">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                    </div>
                    
                        <ReactJson 
                            src={jsonData}
                            onEdit={EditFile}
                            onAdd={EditFile}
                            onDelete={EditFile}
                            theme="rjv-default"
                        />
                    </div>
                    
             </div>       
        )
            }
        {/*train assistant section*/}
        <div className="space-x-2 p-4 mt-4">
            <p  className="font-semibold text-sm">
                Test your assistant
            </p>
            <div className="mt-4 mb-1">
                <form className="" onSubmit={handleFormSubmit}>
                    <p className="text-gray-400 text-sm mb-1">Enter your question</p>
                    <textarea
                        className="border p-2 w-full h-16"
                        value={question}
                        onChange={handleTextareaChange}
                    ></textarea>
                    <button
                        className="flex items-center bg-white border border-gray-200 px-4 py-1 mt-1 rounded-md shadow w-50 ml-auto text-sm"
                        type="submit"
                    >
                        <img src={submit_icon} alt="Start conversation" className="w-auto h-auto object-cover pr-2" />
                        <span className="text-semibold text-black text-sm">Get Answer and Code</span>
                    </button>

                </form>
                {connecting && <div className='flex items-center'>
                        <img src={loading} alt="loading" className='w-12 ' />
                        <p className='text-gray-800 text-sm'>Fetching the answer</p>
                        </div>}
            </div>
            <div className='flex item-start'>
                
                    <div className="w-1/2 flex flex-col mr-2">
                        <p className="text-gray-400 text-sm mb-1">Answer</p>
                        <div className="border p-2 h-48 bg-gray-50">
                        
                        {messagesContent && (
                            <div className="text-sm p-4 " style={{ maxHeight: '100%', overflowY: 'auto' }}>
                                <div
                                className="markdown"
                                dangerouslySetInnerHTML={getHtml()}
                                />
                            </div>
                        )}

                        
                        </div>
                    </div>

                    <div className="w-1/2 flex flex-col ml-2">
                        <p className="text-gray-400 text-sm mb-1">Raw Code</p>
                        <div className="border  h-48 bg-gray-50">
                        {Array.isArray(SQLCode) && SQLCode.map((item, index) => (
                             <MonacoEditor
                                height={codeLength}
                                language="sql"
                                theme="vs-light"
                                value={item}
                                options={{
                                    wordWrap: 'on',
                                    wordWrapColumn: 80,  // You can adjust this value to set the desired width
                                    wrappingIndent: 'indent'  // Indent wrapped lines
                                }}
                                
                            /> ))}
                        </div>
                    </div>
                </div>

                <div className="mt-4 mb-1">
                    <p className="text-gray-400 text-sm mb-1">Evaluate Answer</p> 
                    <div>
                        <div className='flex item-center'>
                            <p className="text-gray-400 text-sm mb-1">Is the answer correct?</p>
                            <button className="bg-white border border-gray-200  px-2 mb-1 ml-4  rounded-md shadow text-[#1E293B] text-sm" onClick={() => handleCorrectChange(true)}>Yes</button>
                            <button className="bg-white border border-gray-200  px-2 mb-1 ml-4  rounded-md shadow text-[#1E293B] text-sm" onClick={() => handleCorrectChange(false)}>No</button>
                        </div>
                        

                        {isCorrect === true ? (
                            <div>
                                {Array.isArray(currentAssist) && currentAssist.map((item, index) => (
                                    <p className="text-green-600 text-sm">Congratulations! {item.AssistantName} did a great job! Please save the record to make sure everyone in your team can get right answers when they ask similar questions.</p>
                                ))}
                                <div className='py-4'> 
                            <button className="flex items-center justify-center bg-white border border-gray-200 p-1 px-4 mb-1 ml-auto rounded-md shadow text-[#1E293B] text-sm" onClick={SaveQuery}>
                                    <svg width="17" height="16" className='w-5 h-5 mr-1 text-[#1E293B] hover:border-grey-200' viewBox="0 0 17 16" fill="var(--color)" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.83398 8.66536H3.83398V7.33203H7.83398V3.33203H9.16732V7.33203H13.1673V8.66536H9.16732V12.6654H7.83398V8.66536Z"/>
                                    </svg>
                                    <p>Save this feedback</p>
                            </button>
                </div>
                                
                            </div>
                        ) :isCorrect === false && (
                            <form onSubmit={handleSaveQuery}>
                                
                                <div  className="border  h-auto bg-gray-50">
                                    <div className=' mt-1 mb-1 ml-4 mr-4 '>
                                        <label className="text-gray-800 text-sm">Why is it wrong?</label>
                                        <input className='ml-4 mt-1  px-2 border rounded-md shadow-sm text-sm' 
                                        value={reasonMessage} onChange={(e) => setResonMessage(e.target.value)}></input>
                                    </div>
                                    <p className=" mt-1 mb-1 ml-4 mr-4 text-gray-800 text-sm">Please input the right code below:</p>
                                    <MonacoEditor
                                        height="100px"
                                        language="sql"
                                        value={newSQLCode}
                                        onChange={handleEditorChange}
                                        options={{ automaticLayout: true }}
                                    />
                                    <div className="flex items-center bg-white border border-gray-200 px-4 py-1 my-2 mr-4 rounded-md shadow w-20 ml-auto text-sm" onClick={RunQuery} > 
                                        <img src={submit_icon} alt="Start conversation" className="w-auto h-auto object-cover pr-2" />
                                        <span className="text-semibold text-black text-sm">Run</span>
                                    </div>
                                    {rawData.length > 0 && rawData[0]  && (
                                        <DataTable data={rawData} />
                                    )}
                                </div>
                            <div className='py-4 my-2'> 
                            <button className="flex items-center justify-center bg-white border border-gray-200 p-1 px-4 mb-1 ml-auto rounded-md shadow text-[#1E293B] text-sm" >
                                    <svg width="17" height="16" className='w-5 h-5 mr-1 text-[#1E293B] hover:border-grey-200' viewBox="0 0 17 16" fill="var(--color)" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.83398 8.66536H3.83398V7.33203H7.83398V3.33203H9.16732V7.33203H13.1673V8.66536H9.16732V12.6654H7.83398V8.66536Z"/>
                                    </svg>
                                    <p>Save this feedback</p>
                            </button>
                </div>
                            </form>
                            
                        )}
                    </div>

                </div>

                
            
        </div>


     
      {/* Add more details about the assistant here */}
    </div>
  );
}

export default EditAssistants;
