import React, { useState, useEffect, useRef } from 'react';
import delete_icon from "../assets/Delete.svg";
import share_icon from "../assets/share.svg";
import edit_icon from "../assets/edit_grey.png";
import download_icon from "../assets/download.svg";
import { useNavigate } from 'react-router-dom';

import ShareModal from "./ShareAssistantsModal"



function ActionMenu({ buttonIcon, itemId, page,user ,setState = null,setData = null,setDelete=null,itemName=null}) {
    const [isModalOpen, setModalOpen] = useState(false);

    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null); // Reference to the menu element
    const navigate = useNavigate();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);


    //handle file download
    const downloadFile = (itemId) => {
        console.log('The file to download:', itemId);
        // Add logic to get download url from backend

        const downloadFile = async () => {
            try {
              if (user && user.uid) {
                
                const response = await fetch('https://api.qquest.io/file-download', {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${user.token}`
                  },
                  body: JSON.stringify({ file_key:itemId}),
                });
                const data = await response.json();
                console.log(data)
                const downloadUrl = data.data;

                // Trigger the download
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = itemId.split('/').pop(); // Optionally specify a filename
                link.target = '_blank'; 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

              } else {
                navigate('/login');
              }
            } catch (error) {
              console.log("Error in downloading the file:", error);
            }
          };
        
            downloadFile();

    };

    //handle file delete
    const DeleteFile = (itemId) => {

        setDelete({
            type:"Delete File",
            key:itemId,
            content:{}
        })
    };
    
    // Handle changes in the JSON data
    const handleEditFile = (edit) => {
        setState(itemId);
        console.log(itemId)
        setShowMenu(false)
        //fetch file content
        const fetchFileContent = async () => {
            try {
              if (user && user.uid) {
                
                const response = await fetch('https://api.qquest.io/file-content-fetch', {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${user.token}`
                  },
                  body: JSON.stringify({ UserId: user.uid ,file_key:itemId}),
                });
                const data = await response.json();
                //console.log(JSON.parse(data.data[0].content));
                setData(JSON.parse(data.data[0].content));
              } else {
                navigate('/login');
              }
            } catch (error) {
              console.log("Error fetching data sources:", error);
            }
          };
        fetchFileContent()

    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            // If the menu is open and the click is outside the menu, close the menu
            if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener on cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]); // Effect dependencies

    const DeleteDataSource = (itemId) => {
        console.log('Deleting data source with ID:', itemId);
        // Add logic to delete the data source here
        if (itemId === "Active") {
            alert("There are one or more assistants linked to this data source. Please delete all associated assistants before attempting to delete the data source.");
        } else {
            console.log("continue");

            fetch('https://api.qquest.io/data-source-deletion', {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify({ source_id: itemId }),
              })
              .then((res) => {
                console.log(res.json());  // Log the full response object
                setShowMenu(false);
                window.location.reload();

            })
              .catch((error) => {
                console.log("Error deleting data sources:", error);
              });

        }
    };

    const DeleteAssistant = (itemId) => {
        console.log('Deleting assistant with ID:', itemId);
        // Add logic to delete the data source here
        
            console.log("continue");

            fetch('https://api.qquest.io/assistant-deletion', {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${user.token}`,
                },
                body: JSON.stringify({user_id:user.uid, AssistantID: itemId }),
              })
              .then((res) => {
                console.log(res.json());  // Log the full response object
                setShowMenu(false);
                window.location.reload();

            })
              .catch((error) => {
                console.log("Error deleting data sources:", error);
              });

        
    };
    

    return (
        <div className="action-button">
            <img src={buttonIcon} alt="More actions" onClick={toggleMenu} className="cursor-pointer" />
            {showMenu && page === "DataSource"  ? (
                <div ref={menuRef} className="absolute right-5 mt-2 py-2 w-32 bg-white rounded-md shadow-xl z-50">
                    <ul>
                        <li className="flex items-center block pl-4 py-1 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" onClick={() => DeleteDataSource(itemId)}>
                            <img src={delete_icon} alt="Delete Icon" className="inline-block mr-2" />
                            Delete
                        </li>
                    </ul>
                </div>
            ) : showMenu && page === "Assistants - Owner" ? (
                <div ref={menuRef} className="absolute right-5 mt-2 py-2 w-40 bg-white rounded-md shadow-xl z-50">
                    <ul>
                        <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => navigate(`/dashboard/assistants/${itemId}`)}>
                            <img src={edit_icon} alt="Edit Icon" className="inline-block mr-2" />
                            Edit
                        </li>
                        <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={openModal}>
                            <img src={share_icon} alt="Share Icon" className="inline-block mr-2" />
                            Share
                        </li>
                        <ShareModal isOpen={isModalOpen} onClose={closeModal} user = {user} itemId ={itemId} itemName = {itemName}>
              
                        </ShareModal>
                        <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => DeleteAssistant(itemId)}>
                            <img src={delete_icon} alt="Delete Icon" className="inline-block mr-2" />
                            Delete
                        </li>
                    </ul>
                </div>
            ) : showMenu && page === "Assistants - Admin" ? (
              <div ref={menuRef} className="absolute right-5 mt-2 py-2 w-40 bg-white rounded-md shadow-xl z-50">
                  <ul>
                      <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => navigate(`/dashboard/assistants/${itemId}`)}>
                          <img src={edit_icon} alt="Edit Icon" className="inline-block mr-2" />
                          Edit
                      </li>
                      <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={openModal}>
                          <img src={share_icon} alt="Share Icon" className="inline-block mr-2" />
                          Share
                      </li>
                      <ShareModal isOpen={isModalOpen} onClose={closeModal} user = {user} itemId ={itemId} itemName = {itemName}>
            
                      </ShareModal>
                     
                  </ul>
              </div>
          ) : showMenu && page === "Files" && (
                <div ref={menuRef} className="absolute right-5 mt-2 py-2 w-40 bg-white rounded-md shadow-xl z-50">
                    <ul>
                        <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleEditFile()}>
                            <img src={edit_icon} alt="Download Icon" className="inline-block mr-2" />
                            Edit
                        </li>
                        <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => downloadFile(itemId)}>
                            <img src={download_icon} alt="Download Icon" className="inline-block mr-2" />
                            Download
                        </li>
                        <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => DeleteFile(itemId)}>
                            <img src={delete_icon} alt="Delete Icon" className="inline-block mr-2" />
                            Delete
                        </li>
                    </ul>
                </div>
            )
            }
            
              
        </div>
    );
}

export default ActionMenu;
