import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetchDataSources from '../hooks/useFetchDataSources';

const CreateAssistantModal = ({ isOpen, onClose, user }) => {
    
    const [datasource, setDatasource] = useState([]);
    const [selectedDatasource, setSelectedDatasource] = useState('');
    const [AssistantName, setAssistantName] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [newAssistId,setNewAssistId] = useState('');


    const formData = new FormData();

    const navigate = useNavigate();
    useFetchDataSources(user, navigate, setDatasource);

    // console.log(datasource)

    if (!isOpen) return null;

    const handleTrain = () => {
        // Close the modal or perform other cleanup actions
        onClose();

        navigate(`/dashboard/assistants/${newAssistId}`);
      };

      const handleRefresh = () => {
        // Close the modal or perform other cleanup actions
        onClose();

        
      };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const field = e.target.id;
        if (field === "metrics") {
            formData.append("MetricFiles", selectedFile);
        } else if (field === "dictionary")
        {
            formData.append("DictionaryFiles", selectedFile);
        } else {
            console.log("There is no file attached");
        }

    };

    const CreateAssistant = (e) => {
        e.preventDefault();

        formData.append("user_id", user.uid);
        formData.append("email", user.email);
        formData.append("assist_name", AssistantName);
        formData.append("database",selectedDatasource);
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        fetch( 'https://api.qquest.io/create-assistant-portal', {
            // mode: 'no-cors',
             method:'POST',
             body: formData,
             headers: {
                'Authorization': `Bearer ${user.token}`,
              }
         })
         .then(response => response.json())
         .then(data => {
            setNewAssistId(data.myAssistant)
             if('error' in data) {
                setErrorMessage(data.error)
             }else{
                setSuccessMessage('The data source is connected successfully.');
             }
             
             
         })
         .catch(error => {
            setErrorMessage( "Cannot create the assistant. Please RE-LOGIN and try to add a new assistant.");
         })

    }

    


    return (
        <div style={{
            position: 'fixed', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div style={{
                padding: 20,
                background: '#fff',
                borderRadius: 8,
                display: 'flex',
                flexDirection: 'column',
                gap: 10
            }}>
<div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="assistant-modal">
    <div className="relative top-20 mx-auto p-5 px-10 border w-full max-w-4xl shadow-lg rounded-md bg-white">
        <div className="relative mt-3 mb-8 flex items-center justify-between">
            <h1 className="text-lg font-bold text-gray-900 ">Create Assistant</h1>
            <button onClick={onClose} className="ml-4 flex-shrink-0">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            </button>
        </div>

        <form className="" onSubmit={CreateAssistant} >
            <div>
                <p className="text-sm font-bold text-gray-900 mb-5 ">Basic Information</p>
                <div className="mb-2 flex items-center">
                    <label className="flex-shrink-0 text-sm font-medium text-gray-500 mt-1 mr-20">Assistant Name</label>
                    <input type="text" placeholder="Enter name" className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md shadow-sm max-w-[442px]" value={AssistantName} onChange={(e) => setAssistantName(e.target.value)} required/>
                </div>
                <div className="mb-8 flex items-center">
                    <label className="flex-shrink-0  text-sm font-medium text-gray-500 mt-1 mr-14 ">Select Data Source</label>
                    <select className="ml-4 mt-1 px-2 py-1 text-sm flex-1 border rounded-md shadow-sm" value={selectedDatasource} onChange={e => setSelectedDatasource(e.target.value)}>
                        <option className='text-gray-400'  >Select data source</option>
                        {/* Populate with options here */}
                        {datasource.map((source, index) => (
                            <option key={index} value={source.name}>
                            {source.schema_name ? `${source.name} - ${source.schema_name}` : source.name}
                            </option>
                        ))}
                    </select>
                    <a href="/dashboard/DataSource" className="flex-shrink-0 ml-4 mt-1 p-2 text-blue-500 hover:text-blue-700 text-sm">Add new data source</a>
                </div>
            </div>
            
            <div>
                <p className="text-sm font-bold text-gray-900 mb-5">Training files (Recommend)</p>
                <div className="mb-2 flex items-center">
                    <label className="flex-shrink-0 text-sm font-medium text-gray-500 mt-1 mr-16">Metrics (JSON Only)</label>
                    <input type="file" className="ml-4 mt-1 px-2 py-1 flex-1 border rounded-md shadow-sm text-sm max-w-[446px]" id="metrics" onChange={handleFileChange} accept=".json" />
                    <a href="https://drive.google.com/drive/folders/1arLbu1WBaBIAcYLXEU3bTY6d8Aw8lSq_?usp=sharing" className="flex-shrink-0 ml-6 mt-1 p-2 text-blue-500 hover:text-blue-700 text-sm">Download template</a>
                </div>
                <div className="mb-8 flex items-center">
                    <label className="flex-shrink-0 text-sm font-medium text-gray-500 mt-1 mr-3">Data Dictionary (JSON Only)</label>
                    <input type="file" className="ml-4 mt-1 px-2 py-1 flex-1 border rounded-md shadow-sm text-sm max-w-[446px]" id="dictionary" onChange={handleFileChange} accept=".json"/>
                    <a href="https://drive.google.com/drive/folders/1arLbu1WBaBIAcYLXEU3bTY6d8Aw8lSq_?usp=sharing" className="flex-shrink-0 ml-6 mt-1 p-2 text-blue-500 hover:text-blue-700 text-sm">Download template</a>
                </div>
                <div className=" text-sm font-light flex items-center justify-between mb-8">
                    <p className='text-gray-400'>You can always go to the Manage Assistant page to train and test your assistant.</p>
                </div>
                <div className=" text-sm flex items-center">
                    <button type="submit" className="ml-auto bg-[#F58F29] border border-gray-200 p-1 mt-1 mb-1 rounded-md shadow w-40">
                        <p className="text-center text-white">Create assistant</p>
                    </button>
                </div>
              
            </div>
           
        </form>
        {successMessage && <p className="flex items-center bg-green-200 text-green-800 p-2 rounded-md mb-4 text-sm">{successMessage}
            <span
                className="ml-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                onClick={handleRefresh}
                >
                Close
            </span> 
            <p className='mx-1'>|</p>
            <span
                className=" text-blue-500 hover:text-blue-700 cursor-pointer"
                onClick={handleTrain}
                >
                Train Assistant
            </span> 
            <p className='mx-1'>|</p>

                <a href="https://chromewebstore.google.com/detail/qquest/gapafchdkenaimpejcgoaeaaejbehcdl" target="_blank" rel="noopener noreferrer"
                    className=" text-blue-500 hover:text-blue-700 cursor-pointer"
                >
                    Start Conversation in Chrome Extension
                </a>
            </p>}
        {errorMessage && <p className="flex items-center bg-red-200 text-red-800 p-2 rounded-md mb-4 text-sm">{errorMessage}
            <span
                className="ml-2 text-blue-500 hover:text-blue-700 cursor-pointer"
                onClick={handleRefresh}
                >
                Close
            </span> 
            <p className='mx-1'>|</p>
            <span
                className=" text-blue-500 hover:text-blue-700 cursor-pointer"
                onClick={handleTrain}
                >
                Train Assistant
            </span> 
        </p>}
    </div>
</div>

                
                
            </div>
        </div>
    );
};


export default CreateAssistantModal;