import { useParams } from 'react-router-dom';
import React, {useState,useEffect} from "react";
import useFetchDataModels from '../hooks/useFetchDataModel';
import ColumnModal from "./ViewColumnModal";
import { useAuth } from '../hooks/useAuth';
import edit_icon from "../assets/edit_grey.png";
import table_icon from "../assets/list-bullet.png"
import down_icon from "../assets/down.png"

import { useNavigate } from 'react-router-dom';
import firebase from '../firebase/firebaseConfig';
const { format } = require('date-fns/format');



function DataModel() {
  const { source_id } = useParams();
  const [datamodels, setDataModel] = useState([]);
  const { user,loading } = useAuth();
  const navigate = useNavigate();
  const [content,setContent] = useState(null);
  const [database_name,setDatabaseName] = useState('')
  const [tables, setTables] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' for ascending, 'desc' for descending
  const [verified, setVerified] = useState([]);
  const [modelloading, setModelLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state


  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
//   const [displayContent, setFilteredTables] = useState(null);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to track the selected item

  const openModal = (item) => {
    setSelectedItem(item); // Set the selected item when opening the modal
    setIsModalOpen(true);
};
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null); // Clear the selected item when closing the modal

  };

  useEffect(() => {
    // Redirect if not logged in and not loading
    if (!user && !loading) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  useFetchDataModels(user, navigate, setDataModel,source_id);
//   console.log(datamodels)
let parsedContent;
  useEffect(() => {
    if (datamodels && datamodels.content) {
        setModelLoading(false)
        try {
             parsedContent = datamodels.content; // Parse the JSON string
            console.log(typeof parsedContent)
              // Check if parsedContent is still a string
            if (typeof parsedContent === 'string') {
                parsedContent = JSON.parse(parsedContent); // Parse again if it is still a string
            }

            console.log("Parsed content:", parsedContent); // Log parsed object
            setContent(parsedContent); // Set the parsed content
          } catch (error) {
            console.error("Failed to parse JSON:", error);
          }
          const nameParts = parsedContent.name.split(" ");
      setDatabaseName(nameParts[nameParts.length - 1]);
    }
  }, [datamodels]);

  useEffect(() => {
    console.log(typeof content)
    if (content && typeof content === 'object') {
        setDescription(content.description)
        setTables(content.tables)
        const verifiedList = content.tables.map(item => item.verified);

        setVerified(verifiedList)
        // console.log("Content:", content); // Log the entire content to inspect
        console.log("verified:", verified)
      }
  
  }, [content]);

  const handleEditClick = () => {
        setIsEditing(true);
    };


    const handleSave = () => {
        // Here, you can add logic to save the updated description
        // For example, send the new description to your backend or update the state
        const current_date = new Date()
        const update_log = {
            updated_at:current_date,
            updated_by: "User",
            update_details: "Update data source description",
            need_to_update_file: true
        }

        const dbRef = firebase.database().ref(`data_source/${source_id}`)
        dbRef.update({'description':description})
            .then(() => console.log("description is updated"))
            .catch(error => console.log("Failed to update description:", error));
        dbRef.update({'update_log':update_log})
            .then(() => console.log("update log is updated"))
            .catch(error => console.log("Failed to update log:", error));
        setIsEditing(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value === '') {
            // If the search bar is cleared, show all tables again
            setTables(content.tables);
          }
    };

    const handleFilterClick = () => {
        const filtered = content.tables.filter(table =>
            table.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setTables(filtered);
          console.log('Filter button clicked');
    };

    const handleSortClick = () => {
        const sortedTables = [...tables].sort((a, b) => {
            // Compare table names based on current sort direction
            if (sortDirection === 'asc') {
              return a.name.localeCompare(b.name);
            } else {
              return b.name.localeCompare(a.name);
            }
          });
      
          // Toggle sort direction for next clicks
          setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
          setTables(sortedTables);
          console.log('Sort button clicked, current sort direction:', sortDirection);
    };

    const viewColumnClick = () => {
        // Implement sort logic here
        console.log('Sort button clicked');
    };

    if (modelloading) {
        return <div  className="flex items-center space-x-2 p-4">
                <p className='text-sm'>Building the data source model. It will take few minutes. Please come back and check later...</p>
                <p className="text-blue-500 text-sm cursor-pointer hover:underline" onClick={() => navigate('/dashboard/DataSource')}>
                    Back
            </p>
            </div>; // Show loading spinner
      }
    
      if (error) {
        return <div>Error: {error}</div>; // Handle error state
      }
  

  return (
    <div>
         
        <div className="flex items-center space-x-2 p-4">
            <p className="text-blue-500 text-sm cursor-pointer hover:underline" onClick={() => navigate('/dashboard/DataSource')}>
                All data sources
            </p>
            <p>&gt;</p>
            <p className='text-sm'>{database_name}</p>
        </div>

        <div className="flex items-center space-x-2 px-4 pt-4 pb-1 ">
            {content &&  (
                        <div className="flex items-start ">
                        <p className='text-sm font-bold'>Description:</p>
                        {isEditing ? (
                            <input 
                                type="text" 
                                value={description} 
                                onChange={(e) => setDescription(e.target.value)} 
                                className="text-sm px-2 border border-gray-300 rounded p-1 mx-2"
                            />
                        ) : (
                            <p className="text-sm px-2 inline">
                            {description}
                            <span className="relative group inline-block">
                                <img
                                    src={edit_icon}
                                    alt="Edit Icon"
                                    className="w-auto h-auto object-cover cursor-pointer pl-4"
                                    onClick={handleEditClick}
                                    style={{ verticalAlign: 'bottom' }}
                                />
                                <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-black text-white text-xs rounded px-3 py-1 whitespace-nowrap">
                                    Edit Schema Description
                                </div>
                            </span>
                        </p>
                       
                        )}
                        
                        {isEditing && (
                            <button 
                                onClick={handleSave} 
                                className="flex items-center justify-center bg-white border border-gray-200 mx-2  text-sm p-1 rounded-md shadow text-[#1E293B]"
                            >
                                
                                Save
                            </button>
                        )}
                    </div>
            )}            
        </div>

        <div className="px-4 py-1">
                <hr className="my-4 border-t border-gray-300" />
        </div> 

        {content && tables && (
            <div>
            <div className="flex items-center justify-between px-4 py-1 mb-4 ">
                <div className="flex items-center space-x-4">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                        className="border border-gray-300 rounded px-2 py-1"
                    />
                    <button
                        onClick={handleFilterClick}
                        className="flex items-center justify-center bg-white border border-gray-200 mx-2  text-sm p-1 rounded-md shadow text-[#1E293B]"
                    >
            
                       <p className='ml-2 font-bold'>Filter</p> 
                        <img src={down_icon} alt='create assistant' className='w-5 h-5 ml-1 mr-2'/> 
                    </button>
                    <p className='text-gray-500'>{content.tables.length} tables</p>
                </div>
                <button
                    onClick={handleSortClick}
                    className="flex items-center justify-center bg-white border border-gray-200 mx-2  text-sm p-1 rounded-md shadow text-[#1E293B]"
                >
                     <p className='ml-2 font-bold'>Sort</p> 
                     <img src={down_icon} alt='create assistant' className='w-5 h-5 ml-1 mr-2'/> 
                </button>
            </div>

            <div className="overflow-x-auto mt-1 mb-1 mx-4 my-1 rounded-md shadow">
            <table className="min-w-full divide-y divide-gray-200  ">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Table name
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Description
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                        </th>
                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            
                        </th>
                        {/* Add other headers as needed */}
                    </tr>
                </thead>
              
                <tbody className="bg-white divide-y divide-gray-200">
                  
                  {Array.isArray(tables) && tables.map((item, index) => (
                        <tr key={index}>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 ">
                                <div className='flex items-center h-full'>
                                <img src={table_icon} className='pr-2'/>
                                {item.name}
                                </div>
                                
                                </td>
                            <td className="px-6 py-3 whitespace-wrap text-sm text-gray-900 max-w-md">{item.description}</td>
                            {verified.length > 0 ? (
                                <td
                                    className={`px-6 py-2 whitespace-nowrap text-sm ${
                                    verified[index] ? 'text-green-700' : 'text-orange-700'
                                    }`}
                                    title={
                                    verified[index]
                                        ? 'Click "View Columns" to get more details.'
                                        : 'Click "View Columns" to verify the details.'
                                    }
                                >
                                    {verified[index] ? 'Verified' : 'Need Review'}
                                </td>
                                ) : (
                                <td></td>
                                )}

                            
                            <td>
                              {/* <button onClick={() => openModal(item)}>
                                <p className="px-6 py-2 whitespace-nowrap text-sm text-blue-800">View Columns</p>
                              </button> */}
                              <div className="flex items-center justify-end">
                              <button className="flex items-center bg-white] border border-gray-200 p-1 px-4 mt-1 mb-1 mr-4 rounded-md shadow w-50" onClick={() => openModal(item)}>
                                  <p className="text-sm text-black mr-1">View Columns</p>
                              </button>
                              </div>
                              
                              
                            </td>
                            
                        </tr>
                    ))}

                           
                </tbody>
            </table>

            <ColumnModal isOpen={isModalOpen} onClose={closeModal} item={selectedItem} source_id={source_id}>
                                    <p>This is the content of the modal</p>
            </ColumnModal>
                            {/* Render other fields as needed */}
        </div>
                    <p className='text-sm text-gray-400 px-4 py-2'> Notes: __DESCRIPTION__ means the content is generated by AI.</p>
            </div>
        )}

 
      {/* Add more details about the assistant here */}
    </div>
  );
}

export default DataModel;
