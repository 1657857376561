import React, {useState,useEffect} from "react";
import down_icon from "../assets/down.png"
import edit_icon from "../assets/edit_grey.png";
import firebase from '../firebase/firebaseConfig';



const ViewColumnModal = ({ isOpen, onClose, item , source_id}) => {
    const [activeTab, setActiveTab] = useState('dimensions');
    const [expandedRows, setExpandedRows] = useState({});
    const [isEditing, setIsEditing] = useState(false);

    const [tableDimensions, setTableDimensions] = useState(null);
    const [tableDetails, setTableDetails] = useState(item);
    const [tableDescription, setTableDescription] = useState(item?.description);
    const [tableMeasures,setTableMeasures] = useState(null)
    const [tableTimeColumns,setTableTimeColumns] = useState(null)
    const [tableOtherColumns,setTableOtherColumns] = useState(null)
    const [tableVerified, setTableVerified] = useState(null)

    useEffect(() => {
        setTableDetails(item)
        setTableDescription(item?.description)
        setTableDimensions(item?.dimensions)
        setTableMeasures(item?.measures)
        setTableTimeColumns(item?.time_dimensions)
        setTableOtherColumns(item?.other_columns)
        setTableVerified(item?.verified)
    },[item])


    // console.log("source_id is :", source_id)
    const toggleRow = (index) => {
        const newState = { ...expandedRows, [index]: !expandedRows[index] };
        setExpandedRows(newState);
      };
    
      const handleEditClick = () => {
        setIsEditing(true);
    };
    

    const handleSave = () => {
        // Here, you can add logic to save the updated description
        // For example, send the new description to your backend or update the state
        const current_date = new Date()
        const update_log = {
            updated_at:current_date,
            updated_by: "User",
            update_details: "Update table description",
            need_to_update_file: true
        }
        const dbRef = firebase.database().ref(`data_source/${source_id}/tables`);

        dbRef.once('value', snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                // console.log(data)
                // Check if data is an array
                if (Array.isArray(data)) {
                    const index = data.findIndex(table => table && table.name === tableDetails.name);
                    // console.log("Index found:", index);
                    if (index !== -1) {
                        // Construct the update path
                        const updatePath = {};
                        updatePath[`tables/${index}/description`] = tableDescription;
                        dbRef.parent.update(updatePath)
                            .then(() => console.log("table description updated successfully!"))
                            .catch(error => console.error("Failed to update table description:", error));
                        dbRef.parent.update({'update_log':update_log})
                        .then(() => console.log("update log is updated"))
                        .catch(error => console.log("Failed to update log:", error));
                    }
                } else {
                    console.error("Data is not an array:", data);
                }
                
            }
        });

       
        setIsEditing(false);
    };


    const handleDimensionChange = (index, field, value) => {
        // Cloning the dimensions array
        const updatedDimensions = [...tableDimensions];
        const current_date = new Date()
        const update_log = {
            updated_at:current_date,
            updated_by: "User",
            update_details: "Update columns details",
            need_to_update_file: true
        }
        
        // console.log("updated Dimensions:", updatedDimensions)
        // Updating the specific field in the dimension object at the specified index
        // console.log("index:",index)
        // console.log("field:",field)
        updatedDimensions[index] = { ...updatedDimensions[index], [field]: value };

        const dbRef = firebase.database().ref(`data_source/${source_id}/tables`);

        dbRef.once('value', snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                // console.log(data)
                // Check if data is an array
                if (Array.isArray(data)) {
                    const index = data.findIndex(table => table && table.name === tableDetails.name);
                    // console.log("Index found:", index);
                    if (index !== -1) {
                        // Construct the update path
                        const updatePath = {};
                        updatePath[`tables/${index}/dimensions`] = updatedDimensions;
                        dbRef.parent.update(updatePath)
                            .then(() => console.log("table dimensions updated successfully!"))
                            .catch(error => console.error("Failed to update table dimension:", error));
                        dbRef.parent.update({'update_log':update_log})
                            .then(() => console.log("update log is updated"))
                            .catch(error => console.log("Failed to update log:", error));
                    }
                } else {
                    console.error("Data is not an array:", data);
                }
                
            }
        });


    
        // Setting the updated dimensions array back to the state
        setTableDimensions(updatedDimensions); // Assuming setTableDimensions is your state setter

      };
    
    const handleMeasureChange = (index, field, value) => {
        // Cloning the measure array
        const updatedMeasures = [...tableMeasures];
        const update_log = {
            updated_at:new Date(),
            updated_by: "User",
            update_details: "Update columns details",
            need_to_update_file: true
        }
        updatedMeasures[index] = { ...updatedMeasures[index], [field]: value };

        const dbRef = firebase.database().ref(`data_source/${source_id}/tables`);

        dbRef.once('value', snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                // console.log(data)
                // Check if data is an array
                if (Array.isArray(data)) {
                    const index = data.findIndex(table => table && table.name === tableDetails.name);
                    // console.log("Index found:", index);
                    if (index !== -1) {
                        // Construct the update path
                        const updatePath = {};
                        updatePath[`tables/${index}/measures`] = updatedMeasures;
                        dbRef.parent.update(updatePath)
                            .then(() => console.log("table measures columns updated successfully!"))
                            .catch(error => console.error("Failed to update table measures:", error));
                        dbRef.parent.update({'update_log':update_log})
                            .then(() => console.log("update log is updated"))
                            .catch(error => console.log("Failed to update log:", error));
                    }
                } else {
                    console.error("Data is not an array:", data);
                }
                
            }
        });


    
        // Setting the updated dimensions array back to the state
        setTableMeasures(updatedMeasures); // Assuming setTableDimensions is your state setter

      };

    const handleTimeChange = (index, field, value) => {
        // Cloning the measure array
        const updatedTimeColumns = [...tableTimeColumns];
        console.log("updatedTimeColumns:",updatedTimeColumns)
        updatedTimeColumns[index] = { ...updatedTimeColumns[index], [field]: value };
        const update_log = {
            updated_at:new Date(),
            updated_by: "User",
            update_details: "Update columns details",
            need_to_update_file: true
        }
        const dbRef = firebase.database().ref(`data_source/${source_id}/tables`);

        dbRef.once('value', snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                // console.log(data)
                // Check if data is an array
                if (Array.isArray(data)) {
                    const index = data.findIndex(table => table && table.name === tableDetails.name);
                    // console.log("Index found:", index);
                    if (index !== -1) {
                        // Construct the update path
                        const updatePath = {};
                        updatePath[`tables/${index}/time_dimensions`] = updatedTimeColumns;
                        dbRef.parent.update(updatePath)
                            .then(() => console.log("table Time columns updated successfully!"))
                            .catch(error => console.error("Failed to update table TimeColumns:", error));
                        dbRef.parent.update({'update_log':update_log})
                            .then(() => console.log("update log is updated"))
                            .catch(error => console.log("Failed to update log:", error));
                    }
                } else {
                    console.error("Data is not an array:", data);
                }
                
            }
        });


    
        // Setting the updated dimensions array back to the state
        setTableTimeColumns(updatedTimeColumns); // Assuming setTableDimensions is your state setter

      };

      const handleOtherChange = (index, field, value) => {
        // Cloning the dimensions array
        const updatedColumns = [...tableOtherColumns];
        const update_log = {
            updated_at:new Date(),
            updated_by: "User",
            update_details: "Update columns details",
            need_to_update_file: true
        }
        // console.log("updated Dimensions:", updatedDimensions)
        // Updating the specific field in the dimension object at the specified index
        // console.log("index:",index)
        // console.log("field:",field)
        updatedColumns[index] = { ...updatedColumns[index], [field]: value };

        const dbRef = firebase.database().ref(`data_source/${source_id}/tables`);

        dbRef.once('value', snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                // console.log(data)
                // Check if data is an array
                if (Array.isArray(data)) {
                    const index = data.findIndex(table => table && table.name === tableDetails.name);
                    // console.log("Index found:", index);
                    if (index !== -1) {
                        // Construct the update path
                        const updatePath = {};
                        updatePath[`tables/${index}/other_columns`] = updatedColumns;
                        dbRef.parent.update(updatePath)
                            .then(() => console.log("table other columns updated successfully!"))
                            .catch(error => console.error("Failed to update table other columns:", error));
                        dbRef.parent.update({'update_log':update_log})
                            .then(() => console.log("update log is updated"))
                            .catch(error => console.log("Failed to update log:", error));
                    }
                } else {
                    console.error("Data is not an array:", data);
                }
                
            }
        });


    
        // Setting the updated dimensions array back to the state
        setTableOtherColumns(updatedColumns); // Assuming setTableDimensions is your state setter

      };

    if (!isOpen) return null;
    //console.log(user.uid)
    
    const close = (e) => {
        onClose();      
    };


    const toggleVerified = () => {
      // Creating a new array copy from the current state
      const newVerified = !tableVerified;
      setTableVerified(newVerified)

      const current_date = new Date()
      const update_log = {
          updated_at:current_date,
          updated_by: "User",
          update_details: "Confirm the table verification",
          need_to_update_file: true
      }

      const dbRef = firebase.database().ref(`data_source/${source_id}/tables`);

        dbRef.once('value', snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                // console.log(data)
                // Check if data is an array
                if (Array.isArray(data)) {
                    const index = data.findIndex(table => table && table.name === tableDetails.name);
                    // console.log("Index found:", index);
                    if (index !== -1) {
                        // Construct the update path
                        const updatePath = {};
                        updatePath[`tables/${index}/verified`] = newVerified;
                        dbRef.parent.update(updatePath)
                            .then(() => console.log("table verified updated successfully!"))
                            .catch(error => console.error("Failed to update table verified:", error));
                        dbRef.parent.update({'update_log':update_log})
                            .then(() => console.log("update log is updated"))
                            .catch(error => console.log("Failed to update log:", error));
                    }
                } else {
                    console.error("Data is not an array:", data);
                }
                
            }
        });
 };


    return (
<div className="fixed top-0 right-0 w-1/3 min-w-[600px] h-[96vh] bg-white flex flex-col items-start justify-center z-50 rounded-lg m-4 shadow-lg">
            <div className="relative mt-3 mb-8 flex items-center justify-between w-full px-4 flex-shrink-0">
                <h1 className="text-lg font-bold text-gray-900">Table - {item.name}</h1>
                <button onClick={onClose} className="ml-4 flex-shrink-0">
                    <svg className="w-5 h-5 text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
            {/* Content of the sub-page goes here */}
            <div className="flex-grow overflow-y-auto m-2 px-3">
                {/* {children} */}
                { tableDescription ? (
                <div className="flex justify-between items-start mb-4">
                    <div className="text-sm font-bold text-gray-600">Description:</div>
                    { isEditing ? (
                            <input 
                                type="text" 
                                value={tableDescription} 
                                onChange={(e) => setTableDescription(e.target.value)} 
                                className="text-sm px-2 border border-gray-300 rounded p-1 mx-2 "
                            />
                        ) : (
                            <p className="text-sm px-2 inline">
                            {tableDescription}
                            <span className="relative group inline-block">
                                <img
                                    src={edit_icon}
                                    alt="Edit Icon"
                                    className="w-auto h-auto object-cover cursor-pointer pl-2"
                                    onClick={handleEditClick}
                                    style={{ verticalAlign: 'bottom' }}
                                />
                                <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-black text-white text-xs rounded px-3 py-1 whitespace-nowrap">
                                    Edit Schema Description
                                </div>
                            </span>
                        </p>
                       
                        )} 
                
                </div>
                ) : (
                    <div>Loading</div>
                )}
                        {isEditing && (
                            <button 
                                onClick={handleSave} 
                                className="flex items-center justify-center bg-white border border-gray-200 mx-2  text-sm p-1 rounded-md shadow text-[#1E293B]"
                            >
                                
                                Save
                            </button>
                        )}

                {/* Tabs Section */}
      <div className="border-b border-gray-200">
        <div className="flex pt-8">
          <div
            className={`mr-6 pb-2 cursor-pointer text-sm ${activeTab === 'dimensions' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-700'}`}
            onClick={() => setActiveTab('dimensions')}
          >
            Dimensions
          </div>
          <div
            className={`mr-6 pb-2 cursor-pointer text-sm ${activeTab === 'measures' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-700'}`}
            onClick={() => setActiveTab('measures')}
          >
            Measures
          </div>
          <div
            className={`mr-6 pb-2 cursor-pointer text-sm ${activeTab === 'timestamp' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-700'}`}
            onClick={() => setActiveTab('timestamp')}
          >
            Timestamp
          </div>
          <div
            className={`mr-6 pb-2 cursor-pointer text-sm ${activeTab === 'other_columns' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-700'}`}
            onClick={() => setActiveTab('other_columns')}
          >
            Unclassified Columns
          </div>
        </div>
      </div>

      {/* Tab Content */}
      <div className="py-4">
        {activeTab === 'dimensions' ? (
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Column Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Column Type
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
             
                {tableDimensions && Array.isArray(tableDimensions) && tableDimensions.map((column, index) => (
                         <>
                         <tr key={`row-${index}`}>
                           <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{column.name}</td>
                           <td className="px-6 py-2 whitespace-wrap text-sm text-gray-900 max-w-md">{column.data_type}</td>
                           <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                             <button onClick={() => toggleRow(index)}>
                             {expandedRows[index]
                                ? <img src={down_icon} alt="Collapse" />
                                : <img src={down_icon} alt="Expand" />
                            }
                             </button>
                           </td>
                         </tr>
                         {expandedRows[index] && (
                           <tr key={`detail-${index}`}>
                             <td colSpan="3" className="bg-gray-100 p-4 pl-6">
                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Description:</label>
                                    <input
                                    type="text"
                                    value={column.description}
                                    onChange={(e) => handleDimensionChange(index, 'description', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Synonyms:</label>
                                    <input
                                    type="text"
                                    value={column.synonyms}
                                    onChange={(e) => handleDimensionChange(index, 'synonyms', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Whether Unique:</label>
                                    <input
                                    type="text"
                                    value={column.unique}
                                    onChange={(e) => handleDimensionChange(index, 'unique', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Metric Expression:</label>
                                    <input
                                    type="text"
                                    value={column.expr}
                                    onChange={(e) => handleDimensionChange(index, 'expr', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Sample Data:</label>
                                    <input
                                    type="text"
                                    value={JSON.stringify(column.sample_data)}
                                    onChange={(e) => handleDimensionChange(index, 'sample_data', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Value Explaination:</label>
                                    <input
                                    type="text"
                                    value={column.value_explanation}
                                    onChange={(e) => handleDimensionChange(index, 'value_explanation', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                
                             </td>
                           </tr>
                         )}
                       </>
                    ))}
              </tbody>
            </table>
          </div>
        ) : activeTab === 'measures' ? (
            <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Column Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Column Type
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
             
                {tableMeasures && Array.isArray(tableMeasures) && tableMeasures.map((column, index) => (
                         <>
                         <tr key={`row-${index}`}>
                           <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{column.name}</td>
                           <td className="px-6 py-2 whitespace-wrap text-sm text-gray-900 max-w-md">{column.data_type}</td>
                           <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                             <button onClick={() => toggleRow(index)}>
                             {expandedRows[index]
                                ? <img src={down_icon} alt="Collapse" />
                                : <img src={down_icon} alt="Expand" />
                            }
                             </button>
                           </td>
                         </tr>
                         {expandedRows[index] && (
                           <tr key={`detail-${index}`}>
                             <td colSpan="3" className="bg-gray-100 p-4">
                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Description:</label>
                                    <input
                                    type="text"
                                    value={column.description}
                                    onChange={(e) => handleMeasureChange(index, 'description', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Synonyms:</label>
                                    <input
                                    type="text"
                                    value={column.synonyms}
                                    onChange={(e) => handleMeasureChange(index, 'synonyms', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Whether Unique:</label>
                                    <input
                                    type="text"
                                    value={column.unique}
                                    onChange={(e) => handleMeasureChange(index, 'unique', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Metric Expression:</label>
                                    <input
                                    type="text"
                                    value={column.expr}
                                    onChange={(e) => handleMeasureChange(index, 'expr', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Min Value:</label>
                                    <input
                                    type="number"
                                    value={column.min_value}
                                    onChange={(e) => handleMeasureChange(index, 'min_value', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Max Value:</label>
                                    <input
                                    type="number"
                                    value={column.max_value}
                                    onChange={(e) => handleMeasureChange(index, 'max_value', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Null Value Count:</label>
                                    <input
                                    type="number"
                                    value={column.null_value_count}
                                    onChange={(e) => handleMeasureChange(index, 'null_value_count', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                
                             </td>
                           </tr>
                         )}
                       </>
                    ))}
              </tbody>
            </table>
          </div>
        ) : activeTab === 'timestamp' ?(
            <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Column Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Column Type
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
             
                {tableTimeColumns && Array.isArray(tableTimeColumns) && tableTimeColumns.map((column, index) => (
                         <>
                         <tr key={`row-${index}`}>
                           <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{column.name}</td>
                           <td className="px-6 py-2 whitespace-wrap text-sm text-gray-900 max-w-md">{column.data_type}</td>
                           <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                             <button onClick={() => toggleRow(index)}>
                             {expandedRows[index]
                                ? <img src={down_icon} alt="Collapse" />
                                : <img src={down_icon} alt="Expand" />
                            }
                             </button>
                           </td>
                         </tr>
                         {expandedRows[index] && (
                           <tr key={`detail-${index}`}>
                             <td colSpan="3" className="bg-gray-100 p-4">
                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Description:</label>
                                    <input
                                    type="text"
                                    value={column.description}
                                    onChange={(e) => handleTimeChange(index, 'description', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Synonyms:</label>
                                    <input
                                    type="text"
                                    value={column.synonyms}
                                    onChange={(e) => handleTimeChange(index, 'synonyms', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Whether Unique:</label>
                                    <input
                                    type="text"
                                    value={column.unique}
                                    onChange={(e) => handleTimeChange(index, 'unique', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Metric Expression:</label>
                                    <input
                                    type="text"
                                    value={column.expr}
                                    onChange={(e) => handleTimeChange(index, 'expr', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Min Value:</label>
                                    <input
                                    type="datetime"
                                    value={column.min_value}
                                    onChange={(e) => handleTimeChange(index, 'min_value', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Max Value:</label>
                                    <input
                                    type="datetime"
                                    value={column.max_value}
                                    onChange={(e) => handleTimeChange(index, 'max_value', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                    <label className="text-gray-700">Null Value Count:</label>
                                    <input
                                    type="number"
                                    value={column.null_value_count}
                                    onChange={(e) => handleTimeChange(index, 'null_value_count', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                
                             </td>
                           </tr>
                         )}
                       </>
                    ))}
              </tbody>
            </table>
          </div>
        ): activeTab === 'other_columns' ? (
            <div>
            <table className="min-w-full divide-y divide-gray-200 rounded-md shadow-md">
              <thead className="bg-gray-50">
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Column Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Column Type
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
             
                {tableOtherColumns && Array.isArray(tableOtherColumns) && tableOtherColumns.map((column, index) => (
                         <>
                         <tr key={`row-${index}`}>
                           <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">{column.name}</td>
                           <td className="px-6 py-2 whitespace-wrap text-sm text-gray-900 max-w-md">{column.data_type}</td>
                           <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                             <button onClick={() => toggleRow(index)}>
                             {expandedRows[index]
                                ? <img src={down_icon} alt="Collapse" />
                                : <img src={down_icon} alt="Expand" />
                            }
                             </button>
                           </td>
                         </tr>
                         {expandedRows[index] && (
                           <tr key={`detail-${index}`}>
                             <td colSpan="3" className="bg-gray-100 p-4">
                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Description:</label>
                                    <input
                                    type="text"
                                    value={column.description}
                                    onChange={(e) => handleOtherChange(index, 'description', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Synonyms:</label>
                                    <input
                                    type="text"
                                    value={column.synonyms}
                                    onChange={(e) => handleOtherChange(index, 'synonyms', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Whether Unique:</label>
                                    <input
                                    type="text"
                                    value={column.unique}
                                    onChange={(e) => handleOtherChange(index, 'unique', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Metric Expression:</label>
                                    <input
                                    type="text"
                                    value={column.expr}
                                    onChange={(e) => handleOtherChange(index, 'expr', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

                                <p className='text-sm px-2 py-2'>
                                <label className="text-gray-700">Sample Data:</label>
                                    <input
                                    type="text"
                                    value={JSON.stringify(column.sample_data)}
                                    onChange={(e) => handleDimensionChange(index, 'sample_data', e.target.value)}
                                    className='ml-4 w-2/3 px-2'
                                    />
                                </p>

              

                                
                             </td>
                           </tr>
                         )}
                       </>
                    ))}
              </tbody>
            </table>
          </div>
        ):(<></>)}
      </div>
        {tableVerified ? ( <div className="flex items-center justify-between  p-4 ">
        <p className="text-sm text-gray-700 mr-4">
          * All the details about the table and columns are up-to-date.
        </p>
        <button className="bg-white text-sm text-black  p-2 rounded-md shadow hover:bg-gray-400 transition duration-200 ease-in-out" onClick={toggleVerified}>
          Revoke
        </button>
      </div>):( <div className="flex items-center justify-between  p-4 ">
        <p className="text-sm text-gray-700 mr-2">
          * I confirm that all the details about the table and columns are up-to-date.
        </p>
        <button className="bg-white text-sm text-black  p-2 rounded-md shadow hover:bg-gray-400 transition duration-200 ease-in-out" onClick={toggleVerified}>
          Confirm
        </button>
      </div>)}
     

            </div>
        </div>
    );
}


export default ViewColumnModal;